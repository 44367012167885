import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"

const Layout = ({ isHomePage, children }) => {
  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  return (
    <div className="global-wrapper" data-is-root-path={isHomePage}>
      <header className="nav-main global-header">
        {/* nav */}
        {isHomePage ? (
          <h3 className="main-heading">
            <Link to="/">{parse(title)}</Link>
          </h3>
        ) : (
          <h3 className="main-heading">
            <Link to="/">{parse(title)}</Link>
          </h3>
        )}
        <ul>
          <li class = "menu"><Link to="/">home</Link></li>
          <li class = "menu"><Link to="/tags">tags</Link></li>
        </ul>
      </header>

      <main>{children}</main>

      <footer>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a>
        {` `}
        And <a href="https://wordpress.org/">WordPress</a>
      </footer>
    </div>
  )
}

export default Layout
